export default {
  namespaced: true,
  state: {
    alert: {
      show: false,
      timeout: 4000,
      timeoutInterval: true,
      type: 'info',
      msg: '',
    },
    progress: false,
    confirm: {
      show: false,
      func: null,
      msg: null,
      color: null,
      btnText: null,
    },
    viewLoad: false,
    post: {
      show: false,
      item: null,
      func: null,
      type: 0,
    },
    sale: {
      show: false,
      item: null,
      func: null,
    },
    memo: {
      show: false,
      data: [],
      func: null,
      batch: null,
      target: {},
    },
    agree: {
      show: false,
      data: [],
      func: null,
      batch: null,
      target: {},
    },
  },
  getters: {
    alert(state) {
      return state.alert;
    },
    progress(state) {
      return state.progress;
    },
    confirm(state) {
      return state.confirm;
    },
    viewLoad(state) {
      return state.viewLoad;
    },
    post(state) {
      return state.post;
    },
    sale(state) {
      return state.sale;
    },
    memo(state) {
      return state.memo;
    },
    agree(state) {
      return state.agree;
    },
  },
  mutations: {
    alert(state, value) {
      state.alert = value;
    },
    progress(state, bool) {
      state.progress = bool;
    },
    confirm(state, value) {
      state.confirm = value;
    },
    viewLoad(state, value) {
      state.viewLoad = value;
    },
    post(state, value) {
      state.post = value;
    },
    sale(state, value) {
      state.sale = value;
    },
    memo(state, value) {
      state.memo = value;
    },
    agree(state, value) {
      state.agree = value;
    },
  },
  actions: {
    alert({ state, commit }, [type, msg, timeout]) {
      commit('alert', { ...state.alert, show: false });
      setTimeout(() => {
        commit('alert', {
          ...state.alert,
          msg,
          type,
          timeout: timeout == null ? 4000 : timeout,
          show: true,
        });

        const startInterval = () => {
          commit('alert', { ...state.alert, timeoutInterval: false });
          const interval = setInterval(() => {
            commit('alert', { ...state.alert, timeout: state.alert.timeout - 100 });
            if (state.alert.timeout === 0) {
              commit('alert', {
                ...state.alert,
                show: false,
                timeoutInterval: true,
              });
              clearInterval(interval);
            }
          }, 100);
        };
        if (state.alert.timeoutInterval) {
          startInterval();
        }
      }, 400);
    },
    // postInit({ state, commit }) {
    //   state.post.func();
    //   commit('post', {
    //     show: false,
    //     item: null,
    //     func: null,
    //     type: 0,
    //   });
    // },
    agree({ state, commit }, [type, msg, timeout]) {
      commit('agree', { ...state.agree, show: false });
      setTimeout(() => {
        commit('agree', {
          ...state.agree,
          msg,
          type,
          timeout: timeout == null ? 4000 : timeout,
          show: true,
        });

        const startInterval = () => {
          commit('agree', { ...state.agree, timeoutInterval: false });
          const interval = setInterval(() => {
            commit('agree', { ...state.agree, timeout: state.agree.timeout - 100 });
            if (state.agree.timeout === 0) {
              commit('agree', {
                ...state.agree,
                show: false,
                timeoutInterval: true,
              });
              clearInterval(interval);
            }
          }, 100);
        };
        if (state.agree.timeoutInterval) {
          startInterval();
        }
      }, 400);
    },
  },
};
