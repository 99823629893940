<template>
  <div class="agree-wrap">
    <v-alert
      :value="agreeState.show"
      :type="agreeState.type"
      transition="slide-y-transition"
      dense
      closable
      close-label="닫기"
      dismissible
    >
      <div style="font-size:20px; margin-top: -3px;margin-bottom: 16px;">
        <span>대범이노베이션 고객관리시스템(CRM) 사용동의</span>
      </div>
      <div>
        본 CRM은 대범이노베이션(이하 회사)의 지적 자산으로, 회사에서 협의하지 않은 업무 외 목적으로의 사용을 절대 금합니다.<br/>
        본 CRM을 이용하는 모든 인력은 회사와 관련된 고객의 정보를 어떤 형태와 방법으로든 외부에 반출해서는 안 되며,<br/>
        본 CRM을 통해 알게된 고객의 정보를 이용해 개인적으로 연락, 사적 이득을 취하기 위한 투자일임을 유도하는 등
        회사에서 허락하지 않은 행위 일체를 금합니다.<br/>
        만약 위 금지사항을 어겨 불법행위로 회사에 손해를 끼칠 경우 회사는 손해배상을 청구하거나 구상권을 청구할 수 있습니다.<br/>
        위 내용에 동의한 인력에 대해서만 본 CRM의 이용 및 접근을 허가합니다.<br/>
      </div>
      <!-- <v-icon
        color="white"
        class="close-icon"
        @click="agreeCng({ ...agreeState, show: false })"
      >
        mdi-close
      </v-icon> -->
    </v-alert>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'DlgAgree',
  computed: {
    ...mapGetters({
      agreeState: 'dialog/agree',
    }),
  },
  methods: {
    ...mapMutations({
      agreeCng: 'dialog/agree',
    }),
    ...mapActions({
      agree: 'dialog/agree',
    }),
    init() {
      console.log('외부클릭');
      // this.agreeState.show = false;
      this.agreeCng({ ...this.agreeState, show: false });
    },
  },
};
</script>

<style scoped lang="scss">
  .agree-wrap {
    // height: 0px;
    z-index: 500;
    width: 40vw;
    position: fixed;
    top: 12%;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(0,0,0,0);
    display: flex;
    align-items: center;
    justify-content: center;
    .v-alert {
      margin-top:10px;
      box-shadow: 0px 2px 8px rgba(0,0,0,0.1) !important;
      font-weight: 500;
      margin-bottom:0px;
      .v-alert__wrapper{
        margin-top: 10px !important;
      }
      // .close-icon {
      //   margin-left: 20px;
      //   opacity: .8;
      //   cursor: pointer;
      //   margin-top:-3px;
      //   &:hover {
      //     opacity: 1;
      //   }
      // }
    }
  }
  .v-icon.v-icon:after {
    margin-top: 10px !important;
  }
</style>
