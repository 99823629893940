<template>
  <div class="outerWrap">
    <div class="innerWrap">
      <div class="box">
        <div class="title">
          <div class="company">
            <img src="../../assets/img/companyIcon.svg" alt="admin">
            <p>{{ companyInfo.name }}</p>
          </div>
        </div>
        <div class="brand">
          <img class="brand" src="../../assets/img/manoadmin.svg" alt="mano-admin">
        </div>
        <div class="formWrap">
          <v-form
            class="form"
            @submit.prevent="loginMethod"
          >
            <v-checkbox
              class="check"
              :label="`아이디 저장`"
              color="primary"
              v-model="user.saved"
            ></v-checkbox>
            <v-checkbox
              style="margin-top: 0px; padding-top: 0px;"
              color="primary"
              v-model="user.agree"
            >
              <template v-slot:label>
                <div class="d-flex flex-row justify-center align-center">
                  <div><span>프로그램 사용동의(<font style="color:crimson">*필수</font>)</span></div>
                  <div class="ml-2">
                    <v-chip
                      @click.native.prevent.stop.capture="openAgreeDetailDlg()"
                      color=""
                      style="cursor: help;"
                    >
                      자세히보기
                    </v-chip>
                  </div>
                </div>
              </template>
            </v-checkbox>
            <v-text-field
              autofocus
              label="아이디"
              outlined
              color="primary"
              hide-details
              class="mb-5"
              v-model="user.id"
            ></v-text-field>
            <v-text-field
              label="비밀번호"
              outlined
              color="primary"
              type="password"
              hide-details
              v-model="user.pw"
            ></v-text-field>
            <v-btn
              type="submit"
              block
              depressed
              color="primary"
              x-large
              class="mt-10 mb-2"
              :disabled="loginDisabled"
            >
              로그인
            </v-btn>
          </v-form>
        </div>
      </div>
    </div>
    <p class="bottomMsg">Copyright © ManoLab. All rights reserved.</p>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'SignIn',
  components: {
  },
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
      companyInfo: 'auth/companyInfo',
      router: 'router/path',
    }),
  },
  data: () => ({
    user: {
      id: '',
      pw: '',
      saved: false,
      agree: false,
    },
    loginDisabled: true,
    agreeFocus: false,
  }),
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
    }),
    ...mapActions({
      alert: 'dialog/alert',
      authorization: 'auth/authorization',
      agree: 'dialog/agree',
    }),
    loginMethod() {
      localStorage.removeItem(`MANOadmin.${this.companyInfo.code}.authToken`);
      if (this.loginDisabled === false && this.user.agree) {
        this.progress(true);
        // 토큰 발급 요청
        this.$socket.emit('users.token.get', {
          id: this.user.id,
          password: this.user.pw,
        }, (resp) => {
          if (resp.result === 'success') {
            // '아이디 저장' 정보 스토리지 저장
            const saveID = JSON.stringify({
              id: this.user.saved ? this.user.id : '',
              saved: this.user.saved,
            });
            localStorage.setItem(`MANOadmin.${this.companyInfo.code}.saveID`, saveID);

            // 토큰 스토리지 저장
            localStorage.setItem(`MANOadmin.${this.companyInfo.code}.authToken`, resp.token);
            this.authorization();
            this.progress(false);
          } else {
            if (resp.name === 'ACCESS_DENIED') {
              this.alert(['error', '비밀번호를 확인해주세요.']);
            } else if (resp.name === 'NO_USER') {
              this.alert(['error', '등록되지 않은 계정입니다.']);
            } else if (resp.name === 'RETIRED_USER') {
              this.alert(['error', '퇴사처리된 계정입니다. 복구를 원할 시 관리자에게 문의하세요.']);
            } else if (resp.name === 'DELETED') {
              this.alert(['error', '삭제된 계정입니다.']);
            } else {
              this.alert(['error', '로그인에 실패하였습니다.']);
            }
            this.progress(false);
          }
        });
      } else {
        this.alert(['error', 'CRM사용동의를 해주세요.']);
      }
    },
    idSavedCheckFunc() {
      // 아이디 저장 체크
      const saveID = JSON.parse(localStorage.getItem(`MANOadmin.${this.companyInfo.code}.saveID`));
      if (saveID && saveID.id && saveID.saved) {
        this.user.id = saveID.id;
        this.user.saved = saveID.saved;
      }
    },
    openAgreeDetailDlg() {
      this.agree(['error', '비밀번호를 확인해주세요.']);
    },
    clicktest() {
      console.log('click');
      console.log(this.agree);
    },
  },
  watch: {
    user: {
      deep: true,
      handler() {
        // 아이디, 비번 입력시 로그인버튼 활성화
        const id = this.user.id.trim();
        const pw = this.user.pw.trim();
        if (id === '' || pw === '') {
          this.loginDisabled = true;
        } else {
          this.loginDisabled = false;
        }
      },
    },
    companyInfo: {
      handler() {
        this.idSavedCheckFunc();
      },
    },
  },
  mounted() {
    this.idSavedCheckFunc();
  },
};
</script>

<style lang="scss" scoped>
  .outerWrap {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    background-color: #fafafa;
    flex-wrap: wrap;
    .bottomMsg {
      width: 100%;
      text-align: center;
      margin-top:8px;
      color:#555;
      font-weight: 300;
      font-size: .8rem;
    }
    .innerWrap {
      width: 400px;
      .box {
        width: 100%;
        border-radius: 30px;
        background-color: #fff;
        border:1px solid #ddd;
        padding:30px;
        padding-top:25px;
        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .company{
            display: flex;
            flex-shrink: 0;
            align-items: center;
            img {
              width: 20px;
              margin-top:2px;
              margin-right: 4px;
            }
            p{
              font-weight: 700;
              font-size: 1rem;
              margin:0;
            }
          }
        }
        .brand {
          width:100%;
          display: flex;
          justify-content: center;
          margin-top:30px;
          margin-bottom:20px;
          img{
            width: 240px;
          }
        }
        .formWrap{
          display: flex;
          justify-content: center;
          .form{
            width: 100%;
          }
        }
      }
    }
  }

</style>
