import { render, staticRenderFns } from "./DashBoard.vue?vue&type=template&id=d47ab382&scoped=true"
import script from "./DashBoard.vue?vue&type=script&lang=js"
export * from "./DashBoard.vue?vue&type=script&lang=js"
import style0 from "./DashBoard.vue?vue&type=style&index=0&id=d47ab382&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d47ab382",
  null
  
)

export default component.exports